<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        class="w-100 pa-8">
                    <v-container fluid>
                        <v-row>
                            <v-col class="pb-0 mb-0">
                                <dynoform header="Varlık Tanımlama"
                                          @change="UpdateFormField($event, 'formdata')"
                                          @changeGrid="UpdateExtData($event, $event.tableName)"
                                          @click="FormButtonClicked($event)"
                                          :attributes="GetFormAttributes"
                                          @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                          :tabname="Math.random().toString()"></dynoform>
                            </v-col>
                        </v-row>
                        <v-row class="mb-10" justify="center">
                            <v-col>
                                <grid groupby=''
                                      :columns="GetExdataColumns('tedarik_kontakkisiler')"
                                      :data="GetExdataItems('tedarik_kontakkisiler')"
                                      :header="$t('TH_MyUsers')"
                                      tableName="tedarik_kontakkisiler"
                                      @change="UpdateExtData($event, 'tedarik_kontakkisiler')"
                                      @changeAttribute="ChangeAttribute($event,'tedarik_kontakkisiler')"
                                      @rowselected="Selected($event, 'tedarik_kontakkisiler')"
                                      @rowclicked="RowClicked($event, 'tedarik_kontakkisiler')"
                                      @SearchInputChanged="SearchInputChanged($event, 'tedarik_kontakkisiler')"
                                      @RowButtonClicked="RowButtonClicked($event, 'tedarik_kontakkisiler')"
                                      @AddRow="AddRow2ExtData($event, 'tedarik_kontakkisiler')"
                                      :attributes="GetExtdataAttributes('tedarik_kontakkisiler')"
                                      @HeaderButtonClicked="HeaderButtonClicked($event, 'tedarik_kontakkisiler')"
                                      :clickrow="1 == 1"
                                      :searchable="false"
                                      addrow="AL_AddUser"
                                      delrow="AL_Delete"
                                      :showselect="true"
                                      :maxLen="GetExdata('tedarik_kontakkisiler').maxLen" />
                            </v-col>
                        </v-row>
                    </v-container>

                </v-form>
            </div>
            <v-dialog v-model="actionBarWait"
                      persistent>
                <v-card color="transparent"
                        dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="actionBarWait" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="actionBarWait" class="primary--text overline">{{ $t("IM_YourTransactionIsInProgress") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-for="(action, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="FormAction(action)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="action.action">
                    {{ $t(action.label) }}
                </v-btn>
            </v-footer>
        </v-main>
    </div>
</template>

<style>
    .grey-lighten-4 {
        background-color: #f5f5f5;
    }

    .v-dialog {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
</style>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);
    var CustomSeparateCharacter = ';';

    var tedarik_kontakkisiler =
    {
        tableName: 'tedarik_kontakkisiler',
        header: 'TH_MyUsers',
        columns: {
            rowid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'rowid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            isAdmin: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'isAdmin',
                width: 0
            },
            adsoyad: {
                type: 'text',
                name: 'adsoyad',
                label: 'FL_Fullname',
                edit: '014X',
                parameters: []
            },
            email: {
                type: 'text',
                name: 'email',
                label: 'FL_Email',
                edit: '014X',
                parameters: [],
                mask: 'email',
                validateMin: 1,
            },
            TelefonKodu: {
                type: 'select',
                name: 'TelefonKodu',
                label: 'Phonecode',
                edit: '014X',
                validateMin: 1,
                options: [

                ],
            },
            telefon: {
                type: 'text',
                name: 'telefon',
                label: 'FL_GSM',
                edit: '014X',
                mask: 'phone',
                validateMin: 1,
            },
            unvan: {
                type: 'text',
                name: 'unvan',
                label: 'FL_Title',
                edit: '014X'
            },
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: "",
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: ''
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };

    var Users_formInfo = {
        tabs: [
            { order: 1, header: 'TH_UserManagement' },

        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_CompanyInformation'
            },
        ],

        Columns: {
            id: {
                type: 'hidden',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'Portal ID',
                edit: '6',
                col: '6',
            },
            sourceid: {
                type: 'hidden',
                group: 1,
                sira: 11,
                name: 'kformsid',
                label: 'KForms ID',
                edit: '',
                col: '6',
            },
            firmaad: {
                type: 'text',
                group: 1,
                sira: 20,
                name: 'firmaad',
                label: 'FL_CompanyTitle',
                edit: '',
                col: '12',
            },
        },
        form: 'Tedarik',
        formid: 1,
        viewName: 'benim',
        viewparameter: '',
        files: [],
        extdata: [tedarik_kontakkisiler],
        actions: [],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    export default {
        name: 'tedarik',
        mixins: [FormMixIn],
        components: {
            dynoform,
            leftmenu,
            kview,
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: String,
            drawer: { type: Boolean, default: true }
        },
        data: function ()
        {
            return {
                valid: false,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                viewName: '',
                username: '',
                form: '',
                formname: 'tedarik',
                openallpanel: [0],
                tedarik: [],
                currentform: 'Tedarik',
                errors: []
            };
        },
        methods: {
            FormLoaded: function ()
            {
                return;
            },

            tedarik_kontakkisiler_After_AddRow: function (item) {
                console.log("this.tedarik_kontakkisiler_After_AddRow", item);
            },

            Selected: function ()
            {
                return;
            },

            FormButtonClicked(col)
            {
                return;
            },

            RowClicked: function (rowdata, table)
            {
                return;
            },

            RowButtonClicked: function (payload, tableName)
            {
                return;
            },

            ChangeAttribute: function (attribute, tableName)
            {
                return;
            },
            ValidateForm(action)
            {
                this.ClearFieldErrors();
                this.errors = [];
                var result = "";

                var kontakkisiler_data = this.GetExdata('tedarik_kontakkisiler');
                var GetExdataColumns = this.GetExdataColumns('tedarik_kontakkisiler');

                var rolesList = [];
                for (var i in kontakkisiler_data.data)
                {
                    var innerData = kontakkisiler_data.data[i];
                    if (innerData['Roles'] == null || innerData['Roles'] == undefined || innerData['Roles'] == '') {
                        this.errors.push(this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Roles') }));
                    }
                    else
                    {
                        rolesList = rolesList.concat(innerData['Roles'].split(CustomSeparateCharacter));
                    }

                    if (innerData['email'] == null || innerData['email'] == undefined || innerData['email'] == '') {
                        this.errors.push(this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Email') }));
                    }
                    else if (innerData['email'].indexOf('@') == -1) {
                        this.errors.push(this.$root.$i18n.t('VE_WarnEmailMustContainAtSymbol'));
                    }
                    else {
                        var column = GetExdataColumns['email'];
                        var err = this.ValidateField(innerData['email'], column);
                        if (err != '') {
                            this.errors.push(err);
                            this.SetExtDataFieldError('tedarik_kontakkisiler', innerData.rowid, 'email');
                        }
                    }

                    if (innerData['telefon'] == null || innerData['telefon'] == undefined || innerData['telefon'] == '') {
                        this.errors.push(this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_GSM') }));
                    }
                    else {
                        var column = GetExdataColumns['telefon'];
                        var err = this.ValidateField(innerData['telefon'], column);
                        if (err != '') {
                            this.errors.push(err);
                            this.SetExtDataFieldError('tedarik_kontakkisiler', innerData.rowid, 'telefon');
                        }
                    }

                    if (innerData['adsoyad'] == null || innerData['adsoyad'] == undefined || innerData['adsoyad'] == '') {
                        this.errors.push(this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Title') }));
                    }
                }

                var admin = rolesList.filter(function (role) {
                    return role == 'A';
                });

                //if (admin.length == 0) {
                //    this.errors.push(this.$root.$i18n.t('VE_WarnMustSelectAtLeast1Admin'));
                //}

                return this.errors;
            },
            AfterExecute(response) {
                this.$store.dispatch('GetUserInfo').then(userInfo =>
                {
                    return;
                });
                this.SetFormView();
            },
            SetFormView()
            {
                var formid = this.$store.getters.GetUserInfo.FirmaNo;
                if (formid == undefined) {
                    this.$store.dispatch('GetUserInfo').then(result => {
                        formid = result.data.userInfo.FirmaNo;
                        if (typeof formid != 'undefined' && formid != 0)
                            Users_formInfo.formid = formid;

                        this.$store.commit('updateFormInfo', Users_formInfo);

                        this.LoadFormData(formid);

                    });

                }
                else {
                    if (typeof formid != 'undefined' && formid != 0)
                        Users_formInfo.formid = formid;

                    this.$store.commit('updateFormInfo', Users_formInfo);

                    this.LoadFormData(formid);
                }
            }
        },

        created: function ()
        {
            tedarik_kontakkisiler.columns.Roles = {
                    type: 'select',
                    name: 'Roles',
                    label: 'FL_Roles',
                    edit: '01234X',
                    validateMin: 1,
                    options: [
                        { text: this.$root.$i18n.t('FL_Admin'), value: 'A' },
                        { text: this.$root.$i18n.t('FL_Accounting'), value: 'M' },
                        { text: this.$root.$i18n.t('FL_Contract'), value: 'C' },
                        { text: this.$root.$i18n.t('FL_Assessments'), value: 'D' },
                        { text: this.$root.$i18n.t('FL_OrderAndWaybills'), value: 'W' },
                        { text: this.$root.$i18n.t('FL_Quatations'), value: 'Q' },
                        { text: this.$root.$i18n.t('RL_Users'), value: 'U' }
                    ],
                    Multiple: true,
                    CustomSeparateCharacter: CustomSeparateCharacter
            };

            var phonecodeReq = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "tedarik_phonecode"
            };
            phonecodeReq.success = function (result)
            {
                tedarik_kontakkisiler.columns.TelefonKodu.options = [];
                result.data.list.forEach(function (obj)
                {
                    var listItem = {
                        text: obj.iso3.toString() + ' | ' + obj.value.toString(),
                        value: obj.value.toString(),
                    };
                    tedarik_kontakkisiler.columns.TelefonKodu.options.push(listItem);
                });
            };
            phonecodeReq.error = function (err)
            {
                console.log(err);
            };

            this.$store.dispatch("GetLOV", phonecodeReq);




            var columns = Users_formInfo.Columns;
            var attributes = {};
            for (var key in columns)
            {
                if (columns.hasOwnProperty(key))
                {
                    attributes[key] = {
                        items: [],
                        readonly: false,
                        hide: '',
                        error: false,
                        disabled: false
                    };
                }
            }
            Users_formInfo.attributes = attributes;
        },
        mounted: function ()
        {
            this.openallpanel = [0];
            this.SetFormView();
        },
        computed: {
            FormAttributes: function ()
            {
                return this.GetFormAttributes;
            },
            UserName()
            {
                return this.GetUserName;
            },
            FormLoadingStatus: {
                get: function ()
                {
                    return this.GetFormLoadingStatus;
                }
            },
        },
        watch: {
            FormLoadingStatus: {
                handler: function (val, oldVal)
                {
                    console.log('FormLoadingStatus changed');
                },
                deep: true
            },
        }
    };
</script>
